
























































































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import galleryStoreModule from "@/store/modules/gallery";
import { Gallery, Shop } from "@/types";

const { mapActions: galleryActions } = createNamespacedHelpers("SHOP_GALLERY");

export default Vue.extend<any, any, any, any>({
  name: "ShopGalleryForm",
  props: {
    gallery: {
      type: Object as PropType<Gallery>,
    },
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    description: "",
    descriptionRules: [(v: string) => !!v || "Description field is required"],
    images: [] as File[],
    displayInImageSlider: true,
  }),
  created() {
    this.resetForm();
  },
  methods: {
    ...galleryActions(["createGallery"]),
    saveGallery() {
      const valid = (
        this.$refs.shopGalleryForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const formData = new FormData();
      formData.append("description", this.description);
      formData.append("displayInImageSlider", `${this.displayInImageSlider}`);
      formData.append("shopId", this.shop._id);

      if (this.images.length) {
        this.images.map((i) => {
          formData.append("files", i);
        });
      }

      this.createGallery(formData).then((post) => {
        if (post) {
          this.$emit("data-saved", true);
        }
      });
    },
    resetForm() {
      (
        this.$refs.shopGalleryForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();

      this.description = "";
    },
    upload(files: FileList) {
      Object.values(files).map((file) => this.images.push(file));
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.images.push(file);
    },
    removeImage(index: number) {
      this.images.splice(index, 1);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_GALLERY")) {
      this.$store.registerModule("SHOP_GALLERY", galleryStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_GALLERY");
  },
});
