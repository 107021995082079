




























































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import galleryStoreModule from "@/store/modules/gallery";
import { Gallery, Shop } from "@/types";
import ShopGalleryForm from "./ShopGalleryForm.vue";

const { mapActions: galleryActions, mapGetters: galleryGetters } =
  createNamespacedHelpers("GALLERY");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: { ShopGalleryForm },
  name: "ShopGallery",
  props: {
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    filters: ["pending", "active", "inactive", "blocked", "All"],
    filter: "All",
    apiUrl: VUE_APP_API_URL,
    showGalleryDialog: false,
    showDeleteDialog: false,
    gallery: undefined as undefined | Gallery,
  }),
  watch: {
    filter() {
      if (this.filter === "All")
        this.fetchGalleryList(`?shopId=${this.shop._id}`);
      else {
        const params = `?shopId=${this.shop._id}&status=${this.filter}`;
        this.fetchGalleryList(params);
      }
    },
  },
  computed: {
    ...galleryGetters(["galleryPage"]),
  },
  created() {
    this.fetchGalleries();
  },
  methods: {
    ...galleryActions(["fetchGalleryList", "updateGallery", "deleteGallery"]),
    fetchGalleries() {
      if (this.shop) {
        const params = `?shopId=${this.shop._id}&_status=active`;
        this.fetchGalleryList(params);
      }
    },
    closeModal() {
      this.showGalleryDialog = false;
      this.fetchGalleryList(`?shopId=${this.shop._id}`);
    },
    update(g: Gallery) {
      this.updateGallery({
        id: g._id,
        gallery: {
          displayInImageSlider: g.displayInImageSlider,
        },
      });
    },
    _delete() {
      this.deleteGallery(`?galleryId=${this.gallery?._id}`).then((g) => {
        if (g) {
          this.fetchGalleries();
          this.showDeleteDialog = false;
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("GALLERY")) {
      this.$store.registerModule("GALLERY", galleryStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("GALLERY");
  },
});
