


















































import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "ShopSetup",
});
