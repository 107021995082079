


















































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import shopStoreModule from "@/store/modules/shop";
import locationStoreModule from "@/store/modules/location";
import { Business, Role, Shop, _Location } from "@/types";
import { PHONE_REGEX } from "@/util/constants";

const { mapActions: shopActions } = createNamespacedHelpers("EDIT_SHOP");

const { mapActions: locationActions } =
  createNamespacedHelpers("SHOP_LOCATIONS");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;
export default Vue.extend<any, any, any, any>({
  name: "EditShopForm",
  props: {
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    apiUrl: VUE_APP_API_URL,
    location: "",
    locations: [] as string[],
    isLoadingLocations: false,
    customLocation: "",
    name: "",
    nameRules: [(v: string) => !!v || "Name field id required"],
    phone: "",
    phoneRules: [
      (v: string) => !!v || "Phone field id required",
      (v: string) => PHONE_REGEX.test(v) || "A valid Phone number is required",
    ],
    ownerName: "",
    ownerNameRules: [(v: string) => !!v || "Owner Name field id required"],
    locationRules: [(v: string) => !!v || "Location field id required"],
    ownerId: "",
    ownerIdRules: [(v: string) => !!v || "Owner Id No. field is required"],
    description: "",
    facebook: "",
    instagram: "",
    website: "",
  }),
  created() {
    this.name = this.shop.name;
    this.location = this.shop.area;
    this.ownerId = this.shop.ownerIdNo;
    this.phone = this.shop.phoneNumber;
    this.ownerName = this.shop.owner;
    this.description = this.shop.description;
    this.website = this.shop.website;
    this.facebook = this.shop.facebookLink;
    this.instagram = this.shop.instagramLink;
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...shopActions(["updateShop"]),
    ...locationActions(["fetchLocationList"]),
    validateForm() {
      const valid = (
        this.$refs.editShopForm as Element & {
          validate: () => boolean;
        }
      )?.validate();

      if (!valid) return;

      this.updateShop({
        id: this.shop._id,
        shop: {
          owner: this.ownerName,
          ownerIdNo: this.ownerId,
          // name: this.name,
          phone: this.phone,
          location: this.location,
          description: this.description,
          website: this.website || null,
          facebookLink: this.facebook || null,
          instagramLink: this.instagram || null,
        },
      });
    },
    searchLocation(q: string) {
      this.customLocation = q;
      if (q) {
        this.isLoadingLocations = true;
        const bid = (this.role.business as Business)._id;
        let params = `?businessId=${bid}&q=${q}`;
        this.fetchLocationList(params).then((locationPage) => {
          if (locationPage) {
            this.locations = locationPage.docs.map((l: _Location) => l.name);
            this.isLoadingLocations = false;
          }
        });
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EDIT_SHOP")) {
      this.$store.registerModule("EDIT_SHOP", shopStoreModule);
    }
    if (!this.$store.hasModule("SHOP_LOCATIONS")) {
      this.$store.registerModule("SHOP_LOCATIONS", locationStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EDIT_SHOP");
    this.$store.unregisterModule("SHOP_LOCATIONS");
  },
});
