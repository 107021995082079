










































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import promoStoreModule from "@/store/modules/promo";
import { Promo, Role, Shop } from "@/types";
import ShopPromoForm from "./ShopPromoForm.vue";

const { mapActions: promoActions, mapGetters: promoGetters } =
  createNamespacedHelpers("SHOP_PROMO_LIST");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: { ShopPromoForm },
  name: "ShopPromoList",
  props: {
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    promo: undefined as undefined | Promo,
    showPromoDialog: false,
    headers: [
      {
        text: "Code",
        align: "start",
        sortable: true,
        value: "code",
      },
      { text: "Discount", value: "discount" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action" },
    ],
    apiUrl: VUE_APP_API_URL,
    options: {} as { page: number },
    rromoerDialog: false,
    showPromoDeleteDialog: false,
  }),
  watch: {
    options: {
      handler() {
        this.fetchPromos();
      },
      deep: true,
    },
  },
  computed: {
    ...promoGetters(["promoPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...promoActions(["fetchPromoList"]),
    ...promoActions(["createPromo", "updatePromo", "deletePromo"]),
    fetchPromos() {
      if (this.shop) {
        const page = this.options.page || 1;
        const params = `?shopId=${this.shop._id}&page=${page}`;
        this.fetchPromoList(params);
      }
    },
    removePromo(promo: Promo) {
      this.deletePromo(`?promoId=${promo._id}`).then((title) => {
        if (title) {
          this.showPromoDeleteDialog = false;
          this.fetchPromos();
        }
      });
    },
    closeModal() {
      this.showPromoDialog = false;
      this.fetchPromos();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_PROMO_LIST")) {
      this.$store.registerModule("SHOP_PROMO_LIST", promoStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_PROMO_LIST");
  },
});
