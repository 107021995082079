var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shop-promo-form",on:{"dragenter":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.dropHandler.apply(null, arguments)}}},[_c('v-form',{ref:"shopPromoForm",on:{"submit":function($event){$event.preventDefault();return _vm.savePromo.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Add a Promo ")]),_c('v-card-text',[_c('h4',[_vm._v("Image")]),_c('div',{staticClass:"promo-image-field-control"},[(_vm.image)?_c('v-icon',{staticStyle:{"float":"right"},attrs:{"color":"primary"}},[_vm._v(" mdi-check-bold ")]):_vm._e(),_c('label',{staticClass:"upload-label",attrs:{"for":"promo-image-field"}},[_c('div',{staticClass:"upload-icon"})]),_c('v-btn',{staticClass:"upload-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.$refs.imageField.click()}}},[_vm._v(" Upload Image ")]),_c('input',{ref:"imageField",staticStyle:{"display":"none"},attrs:{"id":"promo-image-field","type":"file"},on:{"change":function($event){return _vm.upload(_vm.$refs.imageField.files[0])}}}),(_vm.image)?_c('div',[_vm._v(" "+_vm._s(_vm.image.name)+" "),_c('v-btn',{attrs:{"text":"","icon":"","color":"red","title":"Remove image"},on:{"click":function($event){_vm.image = undefined}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()],1),_c('h3',[_vm._v("Dates")]),_c('div',[_c('v-menu',{ref:"dateStartMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.dateStartRules,"name":"Start Date","label":"Start Date","prepend-inner-icon":"mdi-calendar-blank","hint":"DD/MM/YYYY format","persistent-hint":""},on:{"blur":function($event){_vm.dateStart = _vm.parseDate(_vm.dateStartFormatted)}},model:{value:(_vm.dateStartFormatted),callback:function ($$v) {_vm.dateStartFormatted=$$v},expression:"dateStartFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateStartMenu),callback:function ($$v) {_vm.dateStartMenu=$$v},expression:"dateStartMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.dateStartFormatted = _vm.formatDate(_vm.dateStart);
                _vm.dateMenu = false;}},model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}})],1),_c('v-menu',{ref:"dateEndMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.dateEndRules,"name":"End Date","label":"End Date","prepend-inner-icon":"mdi-calendar-blank","hint":"DD/MM/YYYY format","persistent-hint":""},on:{"blur":function($event){_vm.dateEnd = _vm.parseDate(_vm.dateEndFormatted)}},model:{value:(_vm.dateEndFormatted),callback:function ($$v) {_vm.dateEndFormatted=$$v},expression:"dateEndFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateEndMenu),callback:function ($$v) {_vm.dateEndMenu=$$v},expression:"dateEndMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.dateEndFormatted = _vm.formatDate(_vm.dateEnd);
                _vm.dateMenu = false;}},model:{value:(_vm.dateEnd),callback:function ($$v) {_vm.dateEnd=$$v},expression:"dateEnd"}})],1)],1),_c('div',[_c('h3',[_vm._v("Details")]),_c('v-text-field',{attrs:{"rules":_vm.codeRules,"prefix":"#","placeholder":"Code of promo","label":"Code"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('v-text-field',{attrs:{"placeholder":"Discount(%)","label":"Discount"},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}}),_c('v-text-field',{attrs:{"rules":_vm.maxRules,"prefix":_vm.currency,"placeholder":"Maximum discount","label":"Maximum discount"},model:{value:(_vm.max),callback:function ($$v) {_vm.max=$$v},expression:"max"}})],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","text":""}},[_vm._v(" Create promotion ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }