





























































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import postStoreModule from "@/store/modules/post";
import { Service, Shop } from "@/types";
import ShopServiceForm from "./ShopServiceForm.vue";

const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SHOP_SERVICE_LIST");

export default Vue.extend<any, any, any, any>({
  components: { ShopServiceForm },
  name: "ShopServiceList",
  props: {
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    service: undefined as undefined | Service,
    showServiceDialog: false,
    headers: [
      {
        text: "Title",
        align: "start",
        sortable: true,
        value: "title",
      },
      { text: "Price", value: "price" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    rosterDialog: false,
    showServiceDeleteDialog: false,
  }),
  watch: {
    options: {
      handler() {
        this.fetchServices();
      },
      deep: true,
    },
  },
  computed: {
    ...serviceGetters(["postPage"]),
  },
  methods: {
    ...serviceActions([
      "fetchPostList",
      "createPost",
      "updatePost",
      "deletePost",
    ]),
    fetchServices() {
      if (this.shop) {
        const page = this.options.page || 1;
        const params = `?shopId=${this.shop._id}&page=${page}&_status=active&type=service`;
        this.fetchPostList(params);
      }
    },
    removeService(service: Service) {
      this.deletePost(`?postId=${service._id}`).then((title) => {
        if (title) {
          this.showServiceDeleteDialog = false;
        }
      });
    },
    closeModal() {
      this.showServiceDialog = false;
      this.fetchPostList();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_SERVICE_LIST")) {
      this.$store.registerModule("SHOP_SERVICE_LIST", postStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_SERVICE_LIST");
  },
});
