





























































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import postStoreModule from "@/store/modules/post";
import { Voucher, Role, Shop } from "@/types";
import ShopVoucherForm from "./ShopVoucherForm.vue";

const { mapActions: voucherActions, mapGetters: voucherGetters } =
  createNamespacedHelpers("SHOP_VOUCHER_LIST");

export default Vue.extend<any, any, any, any>({
  components: { ShopVoucherForm },
  name: "ShopVoucherList",
  props: {
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    voucher: undefined as undefined | Voucher,
    showVoucherDialog: false,
    headers: [
      {
        text: "Title",
        align: "start",
        sortable: true,
        value: "title",
      },
      { text: "Price", value: "price" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    rosterDialog: false,
    showVoucherDeleteDialog: false,
  }),
  watch: {
    options: {
      handler() {
        this.fetchVouchers();
      },
      deep: true,
    },
  },
  computed: {
    ...voucherGetters(["postPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...voucherActions(["fetchPostList"]),
    ...voucherActions(["createPost", "updatePost", "deletePost"]),
    fetchVouchers() {
      if (this.shop) {
        const page = this.options.page || 1;
        const params = `?shopId=${this.shop._id}&page=${page}&_status=active&type=voucher`;
        this.fetchPostList(params);
      }
    },
    removeVoucher(voucher: Voucher) {
      this.deletePost(`?postId=${voucher._id}`).then((title) => {
        if (title) {
          this.showVoucherDeleteDialog = false;
          this.fetchVouchers();
        }
      });
    },
    closeModal() {
      this.showVoucherDialog = false;
      this.fetchVouchers();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_VOUCHER_LIST")) {
      this.$store.registerModule("SHOP_VOUCHER_LIST", postStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_VOUCHER_LIST");
  },
});
