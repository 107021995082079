import { Module } from "vuex";

import { api } from "@/util/axios";
import { ShopWorkHours } from "@/types";

type ShopWorkHoursState = {
  shopWorkHours: undefined | ShopWorkHours;
};

const shopWorkHours: Module<ShopWorkHoursState, unknown> = {
  namespaced: true,
  state: () => ({
    shopWorkHours: undefined,
  }),
  getters: {
    shopWorkHours: (state) => state.shopWorkHours,
  },
  mutations: {
    SET_SHOP_WORK_HOURS: (state, shopWorkHours) => {
      state.shopWorkHours = shopWorkHours;
    },
  },
  actions: {
    async fetchShopWorkHours(context, params = "") {
      return await api
        .get(`/v1/shop-work-hours${params}`)
        .then((response) => {
          context.commit("SET_SHOP_WORK_HOURS", response.data.shopWorkHours);
          return response.data.shopWorkHours;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createShopWorkHours(context, payload) {
      return await api
        .post(`/v1/shop-work-hours`, payload)
        .then((response) => {
          context.commit("SET_SHOP_WORK_HOURS", response.data.shopWorkHours);
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Success",
          //     type: "success",
          //     text: "ShopWorkHours created",
          //   },
          //   { root: true }
          // );
          return response.data.shopWorkHours;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateShopWorkHours(
      context,
      data: { id: string; shopWorkHours: ShopWorkHours }
    ) {
      api
        .put(`/v1/shop-work-hours/${data.id}`, data.shopWorkHours)
        .then((response) => {
          context.commit("SET_SHOP_WORK_HOURS", response.data.shopWorkHours);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ShopWorkHours updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteShopWorkHours(context, id) {
      api
        .delete(`/v1/shop-work-hours${id}`)
        .then(() => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ShopWorkHours deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    setShopWorkHours(context, shopWorkHours) {
      context.commit("SET_SHOP_WORK_HOURS", shopWorkHours);
    },
  },
};

export default shopWorkHours;
