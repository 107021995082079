import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Sale, Page } from "@/types";

type SaleState = {
  salePage: Page<Sale>;
};

const sale: Module<SaleState, unknown> = {
  namespaced: true,
  state: () => ({
    salePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getSale: (state) => (saleId: string) =>
      state.salePage.docs.find((p) => p._id === saleId),
    salePage: (state) => state.salePage,
  },
  mutations: {
    ADD_SALE: (state, _sale) => {
      let idx = -1;
      state.salePage.docs.map((p, i) => {
        if (p._id === _sale._id) idx = i;
      });
      if (idx === -1) state.salePage.docs.push(_sale);
      else Vue.set(state.salePage.docs, idx, _sale);
    },
    SET_SALE_PAGE: (state, list) => {
      state.salePage = list;
    },
    REMOVE_SALE(state, role) {
      let idx = -1;
      state.salePage.docs.map((r, i) => {
        if (r._id === role._id) idx = i;
      });
      if (idx > -1) state.salePage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchSale(context, params = "") {
      api
        .get(`/v1/sale${params}`)
        .then((response) => {
          context.commit("ADD_SALE", response.data.sale);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchSaleList(context, params = "") {
      return await api
        .get(`/v1/sale${params}`)
        .then((response) => {
          context.commit("SET_SALE_PAGE", response.data.salePage);
          return response.data.salePage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createSale(context, payload) {
      return await api
        .post(`/v1/sale`, payload)
        .then((response) => {
          context.commit("ADD_SALE", response.data.sale);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Sale created",
            },
            { root: true }
          );
          return response.data.sale;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateSale(context, data: { id: string; sale: Sale }) {
      return await api
        .put(`/v1/sale/${data.id}`, data.sale)
        .then((response) => {
          context.commit("ADD_SALE", response.data.sale);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Sale updated",
            },
            { root: true }
          );
          return response.data.sale;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteSale(context, params) {
      return await api
        .delete(`/v1/sale/${params}`)
        .then((response) => {
          context.commit("REMOVE_SALE", response.data.sale);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Sale deleted",
            },
            { root: true }
          );
          return response.data.sale;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default sale;
