


































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import shopWorkHoursStoreModule from "@/store/modules/shopWorkHours";
import { Shop } from "@/types";

const { mapActions: showWokhoursActions } =
  createNamespacedHelpers("SHOP_WORK_HOURS");
export default Vue.extend<any, any, any, any>({
  name: "ShopWorkHourForm",
  props: {
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    hours: {
      monday: {
        from: "",
        to: "",
      },
      tuesday: {
        from: "",
        to: "",
      },
      wednesday: {
        from: "",
        to: "",
      },
      thursday: {
        from: "",
        to: "",
      },
      friday: {
        from: "",
        to: "",
      },
      saturday: {
        from: "",
        to: "",
      },
      sunday: {
        from: "",
        to: "",
      },
      _id: "",
    },
  }),
  created() {
    if (this.shop) {
      this.fetchShopWorkHours(`?shopId=${this.shop._id}`).then((hours) => {
        if (hours) this.hours = hours;
      });
    }
  },
  methods: {
    ...showWokhoursActions(["updateShopWorkHours", "fetchShopWorkHours"]),
    applyAll(apply: boolean) {
      if (apply) {
        this.hours.tuesday = this.hours.monday;
        this.hours.wednesday = this.hours.monday;
        this.hours.thursday = this.hours.monday;
        this.hours.friday = this.hours.monday;
        this.hours.saturday = this.hours.monday;
        this.hours.sunday = this.hours.monday;
      }
    },
    save() {
      this.updateShopWorkHours({
        id: this.hours._id,
        shopWorkHours: {
          monday: {
            from: this.hours.monday.from,
            to: this.hours.monday.to,
          },
          tuesday: {
            from: this.hours.tuesday.from,
            to: this.hours.tuesday.to,
          },
          wednesday: {
            from: this.hours.wednesday.from,
            to: this.hours.wednesday.to,
          },
          thursday: {
            from: this.hours.thursday.from,
            to: this.hours.thursday.to,
          },
          friday: {
            from: this.hours.friday.from,
            to: this.hours.friday.to,
          },
          saturday: {
            from: this.hours.saturday.from,
            to: this.hours.saturday.to,
          },
          sunday: {
            from: this.hours.sunday.from,
            to: this.hours.sunday.to,
          },
        },
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_WORK_HOURS")) {
      this.$store.registerModule("SHOP_WORK_HOURS", shopWorkHoursStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_WORK_HOURS");
  },
});
