
































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import shopStoreModule from "@/store/modules/shop";
import { Business, Role, User } from "@/types";
import ShopSetup from "@/components/shop/ShopSetup.vue";
import ShopServiceList from "@/components/shop/ShopServiceList.vue";
import ShopVoucherList from "@/components/shop/ShopVoucherList.vue";
import ShopMembershipList from "@/components/shop/ShopMembershipList.vue";
import ShopSaleList from "@/components/shop/ShopSaleList.vue";
import ShopPromoList from "@/components/shop/ShopPromoList.vue";
import EditShopForm from "@/components/shop/EditShopForm.vue";
import ShopGallery from "@/components/shop/ShopGallery.vue";
import ShopWorkHourForm from "@/components/shop/ShopWorkHourForm.vue";

const { mapActions: shopActions, mapGetters: shopGetters } =
  createNamespacedHelpers("SHOP_DETAIL");
export default Vue.extend<any, any, any, any>({
  components: {
    ShopSetup,
    ShopServiceList,
    ShopVoucherList,
    ShopMembershipList,
    ShopSaleList,
    ShopPromoList,
    EditShopForm,
    ShopGallery,
    ShopWorkHourForm,
  },
  name: "Shop",
  data: () => ({
    loadingShop: false,
    tab: 0,
    selectShopDialog: true,
    shopAvailable: false,
  }),
  computed: {
    ...shopGetters(["shop"]),
    user(): User {
      return this.$store.getters.user;
    },
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    role: "getShop",
  },
  created() {
    this.getShop();
  },
  methods: {
    ...shopActions(["fetchShop", "updateShop"]),
    selectShop() {
      this.selectShopDialog = false;
      this.getShop();
    },
    getShop() {
      this.loadingShop = true;
      if (this.role && !this.shop) {
        const bid = (this.role.business as Business)._id;

        this.fetchShop(`?businessId=${bid}`).then((shop) => {
          this.loadingShop = false;
          this.shopAvailable =
            shop.availabilityStatus === "approved" ||
            shop.availabilityStatus === "active";
        });
      }
    },
    update() {
      const availabilityStatus = this.shopAvailable ? "active" : "inactive";
      this.updateShop({ id: this.shop._id, shop: { availabilityStatus } });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_DETAIL")) {
      this.$store.registerModule("SHOP_DETAIL", shopStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_DETAIL");
  },
});
