import { Module } from "vuex";

import { api } from "@/util/axios";
import { Page, Shop } from "@/types";

type ShopState = {
  shop: undefined | Shop;
  shopPage: Page<Shop>;
};

const shop: Module<ShopState, unknown> = {
  namespaced: true,
  state: () => ({
    shop: undefined,
    shopPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    shop: (state) => state.shop,
    shopPage: (state) => state.shopPage,
  },
  mutations: {
    SET_SHOP: (state, shop) => {
      state.shop = shop;
    },
    SET_SHOP_PAGE: (state, page) => {
      state.shopPage = page;
    },
  },
  actions: {
    async fetchShop(context, params = "") {
      return await api
        .get(`/v1/shop${params}`)
        .then((response) => {
          context.commit("SET_SHOP", response.data.shop);
          return response.data.shop;
        })
        .catch(() => {
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Request failed!",
          //     type: "error",
          //     text: error.response?.data?.error?.message,
          //   },
          //   { root: true }
          // );
        });
    },
    async createShop(context, payload) {
      return await api
        .post(`/v1/shop`, payload, { timeout: 120000 })
        .then((response) => {
          context.commit("SET_SHOP", response.data.shop);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Shop created",
            },
            { root: true }
          );
          return response.data.shop;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateShop(context, data: { id: string; shop: Shop }) {
      api
        .put(`/v1/shop/${data.id}`, data.shop)
        .then((response) => {
          context.commit("SET_SHOP", response.data.shop);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Shop updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteShop(context, id) {
      api
        .delete(`/v1/shop${id}`)
        .then(() => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Shop deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    setShop(context, shop) {
      context.commit("SET_SHOP", shop);
    },
    async fetchShopList(context, params = "") {
      return await api
        .get(`/v1/shop${params}`)
        .then((response) => {
          context.commit("SET_SHOP_PAGE", response.data.shopPage);
          return response.data.shopPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default shop;
