import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Promo, Page } from "@/types";

type PromoState = {
  promoPage: Page<Promo>;
};

const promo: Module<PromoState, unknown> = {
  namespaced: true,
  state: () => ({
    promoPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getPromo: (state) => (promoId: string) =>
      state.promoPage.docs.find((p) => p._id === promoId),
    promoPage: (state) => state.promoPage,
  },
  mutations: {
    ADD_PROMO: (state, _promo) => {
      let idx = -1;
      state.promoPage.docs.map((r, i) => {
        if (r._id === _promo._id) idx = i;
      });
      if (idx === -1) state.promoPage.docs.push(_promo);
      else Vue.set(state.promoPage.docs, idx, _promo);
    },
    SET_PROMO_PAGE: (state, list) => {
      state.promoPage = list;
    },
    REMOVE_PROMO(state, promo) {
      let idx = -1;
      state.promoPage.docs.map((r, i) => {
        if (r._id === promo._id) idx = i;
      });
      if (idx > -1) state.promoPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchPromo(context, params = "") {
      api
        .get(`/v1/promo${params}`)
        .then((response) => {
          context.commit("ADD_PROMO", response.data.promo);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchPromoList(context, params = "") {
      api
        .get(`/v1/promo${params}`)
        .then((response) => {
          context.commit("SET_PROMO_PAGE", response.data.promoPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createPromo(context, payload) {
      return await api
        .post(`/v1/promo`, payload)
        .then((response) => {
          context.commit("ADD_PROMO", response.data.promo);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Promo created",
            },
            { root: true }
          );
          return response.data.promo;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updatePromo(context, data: { id: string; promo: Promo }) {
      api
        .put(`/v1/promo/${data.id}`, data.promo)
        .then((response) => {
          context.commit("ADD_PROMO", response.data.promo);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Promo updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deletePromo(context, id) {
      api
        .delete(`/v1/promo${id}`)
        .then((response) => {
          context.commit("REMOVE_PROMO", response.data.promo);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Promo deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default promo;
