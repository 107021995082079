import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Post, Page } from "@/types";

type PostState = {
  postPage: Page<Post>;
};

const post: Module<PostState, unknown> = {
  namespaced: true,
  state: () => ({
    postPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getPost: (state) => (postId: string) =>
      state.postPage.docs.find((p) => p._id === postId),
    postPage: (state) => state.postPage,
  },
  mutations: {
    ADD_POST: (state, _post) => {
      let idx = -1;
      state.postPage.docs.map((p, i) => {
        if (p._id === _post._id) idx = i;
      });
      if (idx === -1) state.postPage.docs.push(_post);
      else Vue.set(state.postPage.docs, idx, _post);
    },
    SET_POST_PAGE: (state, list) => {
      state.postPage = list;
    },
    REMOVE_POST(state, role) {
      let idx = -1;
      state.postPage.docs.map((r, i) => {
        if (r._id === role._id) idx = i;
      });
      if (idx > -1) state.postPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchPost(context, params = "") {
      api
        .get(`/v1/post${params}`)
        .then((response) => {
          context.commit("ADD_POST", response.data.post);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchPostList(context, params = "") {
      return await api
        .get(`/v1/post${params}`)
        .then((response) => {
          context.commit("SET_POST_PAGE", response.data.postPage);
          return response.data.postPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createPost(context, payload) {
      return await api
        .post(`/v1/post`, payload)
        .then((response) => {
          context.commit("ADD_POST", response.data.post);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Post created",
            },
            { root: true }
          );
          return response.data.post;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updatePost(context, data: { id: string; post: Post }) {
      return await api
        .put(`/v1/post/${data.id}`, data.post)
        .then((response) => {
          context.commit("ADD_POST", response.data.post);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Post updated",
            },
            { root: true }
          );
          return response.data.post;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deletePost(context, params) {
      return await api
        .delete(`/v1/post/${params}`)
        .then((response) => {
          context.commit("REMOVE_POST", response.data.post);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Post deleted",
            },
            { root: true }
          );
          return response.data.post;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default post;
