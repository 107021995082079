























import Vue, { PropType } from "vue";

import saleStoreModule from "@/store/modules/sale";
import { createNamespacedHelpers } from "vuex";
import { Shop } from "@/types";

const { mapActions: saleActions, mapGetters: saleGetters } =
  createNamespacedHelpers("SHOP_SALE_LIST");

export default Vue.extend<any, any, any, any>({
  name: "ShopSaleList",
  props: {
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Title",
        align: "start",
        sortable: true,
        value: "title",
      },
      { text: "Price", value: "price" },
      { text: "Discount", value: "discount" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  watch: {
    options: {
      handler() {
        this.fetchSales();
      },
      deep: true,
    },
  },
  computed: {
    ...saleGetters(["salePage"]),
  },
  methods: {
    ...saleActions(["fetchSaleList", "createSale", "updateSale", "deleteSale"]),
    fetchSales() {
      if (this.shop) {
        const page = this.options.page || 1;
        const params = `?shopId=${this.shop._id}&page=${page}&_status=active`;
        this.fetchSaleList(params);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_SALE_LIST")) {
      this.$store.registerModule("SHOP_SALE_LIST", saleStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_SALE_LIST");
  },
});
