































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import postStoreModule from "@/store/modules/post";
import { Membership, Role, Shop } from "@/types";
import ShopMembershipForm from "./ShopMembershipForm.vue";

const { mapActions: membershipActions, mapGetters: membershipGetters } =
  createNamespacedHelpers("SHOP_MEMBERSHIP_LIST");

export default Vue.extend<any, any, any, any>({
  components: { ShopMembershipForm },
  name: "ShopMembershipList",
  props: {
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    membership: undefined as undefined | Membership,
    showMembershipDialog: false,
    headers: [
      {
        text: "Title",
        align: "start",
        sortable: true,
        value: "title",
      },
      { text: "Price", value: "price" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    rosterDialog: false,
    showMembershipDeleteDialog: false,
  }),
  watch: {
    options: {
      handler() {
        this.fetchMemberships();
      },
      deep: true,
    },
  },
  computed: {
    ...membershipGetters(["postPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...membershipActions(["fetchPostList"]),
    ...membershipActions(["createPost", "updatePost", "deletePost"]),
    fetchMemberships() {
      if (this.shop) {
        const page = this.options.page || 1;
        const params = `?shopId=${this.shop._id}&page=${page}&_status=active&type=membership`;
        this.fetchPostList(params);
      }
    },
    removeMembership(membership: Membership) {
      this.deletePost(`?postId=${membership._id}`).then((title) => {
        if (title) {
          this.showMembershipDeleteDialog = false;
          this.fetchMemberships();
        }
      });
    },
    closeModal() {
      this.showMembershipDialog = false;
      this.fetchMemberships();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_MEMBERSHIP_LIST")) {
      this.$store.registerModule("SHOP_MEMBERSHIP_LIST", postStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_MEMBERSHIP_LIST");
  },
});
