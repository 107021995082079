







































































































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import postStoreModule from "@/store/modules/post";
import { Post, Shop } from "@/types";

const { mapActions: voucherActions } = createNamespacedHelpers("SHOP_VOUCHER");

export default Vue.extend<any, any, any, any>({
  name: "ShopVoucherForm",
  props: {
    voucher: {
      type: Object as PropType<Post>,
    },
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    title: "",
    description: "",
    price: undefined as undefined | number,
    discount: undefined as undefined | number,
    titleRules: [(v: string) => !!v || "Title is required"],
    images: [] as File[],
    currency: "KES",
    priceRules: [(v: string) => !!v || "Price is required"],
    week: {
      mon: {
        available: false,
      },
      tue: {
        available: false,
      },
      wed: {
        available: false,
      },
      thu: {
        available: false,
      },
      fri: {
        available: false,
      },
      sat: {
        available: false,
      },
      sun: {
        available: false,
      },
    },
  }),
  created() {
    this.resetForm();
  },
  methods: {
    ...voucherActions(["createPost", "updatePost", "deletePost"]),
    saveVoucher() {
      const valid = (
        this.$refs.shopVoucherForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      if (this.voucher && this.voucher._id) {
        const formData = new FormData();

        formData.append("title", this.title);
        formData.append("description", this.description);
        formData.append("price", `${this.price}`);
        formData.append("discount", `${this.discount}`);
        formData.append("shopId", this.shop._id);

        if (this.images.length) {
          this.images.map((i) => {
            formData.append("files", i);
          });
        }
        this.updatePost({
          id: this.voucher._id,
          post: formData,
        }).then((voucher) => {
          if (voucher) {
            this.$emit("data-saved", true);
          }
        });
        return;
      }

      const formData = new FormData();

      formData.append("type", "voucher");
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("price", `${this.price}`);
      formData.append("discount", `${this.discount}`);
      formData.append("category", "Gift Voucher");
      formData.append("bookingsPerHour", `${1}`);
      formData.append("week", JSON.stringify(this.week));
      formData.append("shopId", this.shop._id);

      if (this.images.length) {
        this.images.map((i) => {
          formData.append("files", i);
        });
      }

      this.createPost(formData).then((post) => {
        if (post) {
          this.$emit("data-saved", true);
        }
      });
    },
    resetForm() {
      (
        this.$refs.shopVoucherForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();

      this.title = this.voucher?.title || "";
      this.description = this.voucher?.description || "";
      this.price = this.voucher?.price || undefined;
      this.discount = this.voucher?.discount || undefined;
    },
    upload(files: FileList) {
      Object.values(files).map((file) => this.images.push(file));
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.images.push(file);
    },
    removeImage(index: number) {
      this.images.splice(index, 1);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_VOUCHER")) {
      this.$store.registerModule("SHOP_VOUCHER", postStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_VOUCHER");
  },
});
