var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"540"},model:{value:(_vm.showGalleryDialog),callback:function ($$v) {_vm.showGalleryDialog=$$v},expression:"showGalleryDialog"}},[(_vm.showGalleryDialog)?_c('shop-gallery-form',{attrs:{"shop":_vm.shop},on:{"data-saved":_vm.closeModal,"cancel":function($event){_vm.showGalleryDialog = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"490"},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[(_vm.gallery)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirm to delete gallery ")]),_c('v-card-text',[_vm._v(" This action will delete "+_vm._s(_vm.gallery.description)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm._delete}},[_vm._v(" Agree ")])],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"0 2px"},attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.filter))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((_vm.filters),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.filter = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"6"}},[_c('v-btn',{on:{"click":function($event){_vm.showGalleryDialog = true}}},[_vm._v(" Add ")])],1)],1),_vm._l((_vm.galleryPage.docs),function(g,i){return _c('div',{key:i},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},_vm._l((g.files),function(file,k){return _c('div',{key:k,style:({
          height: '300px',
          width: '300px',
          margin: '5px',
          'background-image': ("url(" + _vm.apiUrl + "/v1/file/" + (file.filename) + ")"),
          'background-size': 'cover',
        })})}),0),_c('h4',[_vm._v(" "+_vm._s(g.description)+" "),_c('v-checkbox',{attrs:{"label":"Display in Image Slider"},on:{"change":function($event){return _vm.update(g)}},model:{value:(g.displayInImageSlider),callback:function ($$v) {_vm.$set(g, "displayInImageSlider", $$v)},expression:"g.displayInImageSlider"}}),_c('v-btn',{on:{"click":function($event){_vm.gallery = g;
          _vm.showDeleteDialog = true;}}},[_vm._v(" Delete ")])],1)])}),(!_vm.galleryPage.docs.length)?_c('div',{staticClass:"no-records"},[_c('i',[_vm._v("No galleries to display")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }