











































































































































































































































































































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import postStoreModule from "@/store/modules/post";
import { Post, Shop } from "@/types";

const { mapActions: serviceActions } = createNamespacedHelpers("SHOP_SERVICE");

export default Vue.extend<any, any, any, any>({
  name: "ShopServiceForm",
  props: {
    service: {
      type: Object as PropType<Post>,
    },
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    title: "",
    category: "",
    week: {
      mon: {
        available: false,
        from: undefined as undefined | number,
        to: undefined as undefined | number,
      },
      tue: {
        available: false,
        from: undefined as undefined | number,
        to: undefined as undefined | number,
      },
      wed: {
        available: false,
        from: undefined as undefined | number,
        to: undefined as undefined | number,
      },
      thu: {
        available: false,
        from: undefined as undefined | number,
        to: undefined as undefined | number,
      },
      fri: {
        available: false,
        from: undefined as undefined | number,
        to: undefined as undefined | number,
      },
      sat: {
        available: false,
        from: undefined as undefined | number,
        to: undefined as undefined | number,
      },
      sun: {
        available: false,
        from: undefined as undefined | number,
        to: undefined as undefined | number,
      },
    },
    description: "",
    price: undefined as undefined | number,
    bookingsPerHour: undefined as undefined | number,
    discount: undefined as undefined | number,
    titleRules: [(v: string) => !!v || "Title is required"],
    images: [] as File[],
    categories: [
      "Beauty",
      "Barber",
      "Hair",
      "Aesthetics",
      "Travel",
      "Activities",
      "Wellness",
      "General Services",
      "Coaching Services",
      "Event Planning",
      "Shopping",
    ],
    categoryRules: [(v: string) => !!v || "Category is required"],
    hours: [
      { label: "12:00 AM", value: 0 },
      { label: "1:00 AM", value: 1 },
      { label: "2:00 AM", value: 2 },
      { label: "3:00 AM", value: 3 },
      { label: "4:00 AM", value: 4 },
      { label: "5:00 AM", value: 5 },
      { label: "6:00 AM", value: 6 },
      { label: "7:00 AM", value: 7 },
      { label: "8:00 AM", value: 8 },
      { label: "9:00 AM", value: 9 },
      { label: "10:00 AM", value: 10 },
      { label: "11:00 AM", value: 11 },
      { label: "12:00 PM", value: 12 },
      { label: "1:00 PM", value: 13 },
      { label: "2:00 PM", value: 14 },
      { label: "3:00 PM", value: 15 },
      { label: "4:00 PM", value: 16 },
      { label: "5:00 PM", value: 17 },
      { label: "6:00 PM", value: 18 },
      { label: "7:00 PM", value: 19 },
      { label: "8:00 PM", value: 20 },
      { label: "9:00 PM", value: 21 },
      { label: "10:00 PM", value: 22 },
      { label: "11:00 PM", value: 23 },
    ],
    bookingsPerHourRules: [],
    currency: "KES",
    priceRules: [(v: string) => !!v || "Price is required"],
  }),
  created() {
    this.resetForm();
  },
  methods: {
    ...serviceActions(["createPost", "updatePost", "deletePost"]),
    saveService() {
      const valid = (
        this.$refs.shopServiceForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      if (this.service && this.service._id) {
        const formData = new FormData();

        formData.append("title", this.title);
        formData.append("category", this.category);
        formData.append("week", JSON.stringify(this.week));
        formData.append("description", this.description);
        formData.append("bookingsPerHour", `${this.bookingsPerHour}`);
        formData.append("price", `${this.price}`);
        formData.append("discount", `${this.discount}`);

        if (this.images.length) {
          this.images.map((i) => {
            formData.append("files", i);
          });
        }
        this.updatePost({
          id: this.service._id,
          post: formData,
        }).then((service) => {
          if (service) {
            this.$emit("data-saved", true);
          }
        });
        return;
      }

      const formData = new FormData();

      formData.append("type", "service");
      formData.append("title", this.title);
      formData.append("category", this.category);
      formData.append("week", JSON.stringify(this.week));
      formData.append("description", this.description);
      formData.append("bookingsPerHour", `${this.bookingsPerHour}`);
      formData.append("price", `${this.price}`);
      formData.append("discount", `${this.discount}`);
      formData.append("shopId", this.shop._id);

      if (this.images.length) {
        this.images.map((i) => {
          formData.append("files", i);
        });
      }

      this.createPost(formData).then((post) => {
        if (post) {
          this.$emit("data-saved", true);
        }
      });
    },
    resetForm() {
      (
        this.$refs.shopServiceForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();

      this.title = this.service?.title || "";
      this.category = this.service?.category || "";
      this.week = this.service?.week || {
        mon: {
          from: undefined,
          to: undefined,
          available: false,
        },
        tue: {
          from: undefined,
          to: undefined,
          available: false,
        },
        wed: {
          from: undefined,
          to: undefined,
          available: false,
        },
        thu: {
          from: undefined,
          to: undefined,
          available: false,
        },
        fri: {
          from: undefined,
          to: undefined,
          available: false,
        },
        sat: {
          from: undefined,
          to: undefined,
          available: false,
        },
        sun: {
          from: undefined,
          to: undefined,
          available: false,
        },
      };
      this.description = this.service?.description || "";
      this.price = this.service?.price || undefined;
      this.bookingsPerHour = this.service?.bookingsPerHour || undefined;
      this.discount = this.service?.discount || undefined;
    },
    upload(files: FileList) {
      Object.values(files).map((file) => this.images.push(file));
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.images.push(file);
    },
    removeImage(index: number) {
      this.images.splice(index, 1);
    },
    fill() {
      if (this.week.mon.to && this.week.mon.from) {
        this.week = {
          mon: this.week.mon,
          tue: {
            from: this.week.mon.from,
            to: this.week.mon.to,
            available: true,
          },
          wed: {
            from: this.week.mon.from,
            to: this.week.mon.to,
            available: true,
          },
          thu: {
            from: this.week.mon.from,
            to: this.week.mon.to,
            available: true,
          },
          fri: {
            from: this.week.mon.from,
            to: this.week.mon.to,
            available: true,
          },
          sat: {
            from: this.week.mon.from,
            to: this.week.mon.to,
            available: true,
          },
          sun: {
            from: this.week.mon.from,
            to: this.week.mon.to,
            available: true,
          },
        };
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_SERVICE")) {
      this.$store.registerModule("SHOP_SERVICE", postStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_SERVICE");
  },
});
