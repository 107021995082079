import { Module } from "vuex";

import { api } from "@/util/axios";
import { Gallery, Page } from "@/types";

type GalleryState = {
  galleryPage: Page<Gallery>;
};

const gallery: Module<GalleryState, unknown> = {
  namespaced: true,
  state: () => ({
    galleryPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    galleryPage: (state) => state.galleryPage,
  },
  mutations: {
    SET_GALLERY_PAGE: (state, page) => {
      state.galleryPage = page;
    },
  },
  actions: {
    async fetchGalleryList(context, params = "") {
      return await api
        .get(`/v1/gallery${params}`)
        .then((response) => {
          context.commit("SET_GALLERY_PAGE", response.data.galleryPage);
          return response.data.galleryPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createGallery(context, payload) {
      return await api
        .post(`/v1/gallery`, payload, { timeout: 120000 })
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Gallery created",
            },
            { root: true }
          );
          return response.data.gallery;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateGallery(context, data: { id: string; gallery: Gallery }) {
      api
        .put(`/v1/gallery/${data.id}`, data.gallery)
        .then((response) => {
          context.commit("SET_GALLERY", response.data.gallery);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Gallery updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteGallery(context, id) {
      return await api
        .delete(`/v1/gallery${id}`)
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Gallery deleted",
            },
            { root: true }
          );
          return response.data.gallery;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default gallery;
