


























































































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import promoStoreModule from "@/store/modules/promo";
import { Promo, Shop } from "@/types";
import { DATE_REGEX } from "@/util/constants";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapActions: promoActions } = createNamespacedHelpers("SHOP_VOUCHER");

export default Vue.extend<any, any, any, any>({
  name: "ShopPromoForm",
  props: {
    promo: {
      type: Object as PropType<Promo>,
    },
    shop: {
      type: Object as PropType<Shop>,
      required: true,
    },
  },
  data: () => ({
    code: "",
    max: undefined as undefined | number,
    discount: undefined as undefined | number,
    codeRules: [(v: string) => !!v || "Code is required"],
    image: undefined as undefined | File,
    currency: "KES",
    maxRules: [(v: string) => !!v || "Maximum discount is required"],
    dateStart: "",
    dateStartRules: [
      (v: string) => !!v || "Date end is required",
      (v: string) => DATE_REGEX.test(v) || "Date end must be valid format",
    ],
    dateEnd: "",
    dateEndRules: [
      (v: string) => !!v || "Date end is required",
      (v: string) => DATE_REGEX.test(v) || "Date end must be valid format",
    ],
    dateStartFormatted: "",
    dateStartMenu: false,
    dateEndFormatted: "",
    dateEndMenu: false,
  }),
  created() {
    this.resetForm();
  },
  methods: {
    ...promoActions(["createPromo", "updatePromo", "deletePromo"]),
    savePromo() {
      const valid = (
        this.$refs.shopPromoForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      const formData = new FormData();
      let dateStart: string | string[] = this.dateStartFormatted;
      dateStart = (dateStart as string).split("/");
      dateStart = `${dateStart[1]}-${dateStart[0]}-${dateStart[2]}`;
      dateStart = new Date(dateStart).toISOString();

      let dateEnd: string | string[] = this.dateEndFormatted;
      dateEnd = (dateEnd as string).split("/");
      dateEnd = `${dateEnd[1]}-${dateEnd[0]}-${dateEnd[2]}`;
      dateEnd = new Date(dateEnd).toISOString();

      formData.append("file", this.image as File);
      formData.append("dateStart", dateStart);
      formData.append("dateEnd", dateEnd);
      formData.append("code", this.code);
      formData.append("max", `${this.max}`);
      formData.append("discount", `${this.discount}`);

      if (this.promo && this.promo._id) {
        this.updatePromo({
          id: this.promo._id,
          promo: formData,
        }).then((promo) => {
          if (promo) {
            this.$emit("data-saved", true);
          }
        });
        return;
      }
      this.createPromo(formData).then((promo) => {
        if (promo) {
          this.$emit("data-saved", true);
        }
      });
    },
    resetForm() {
      (
        this.$refs.shopPromoForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();

      this.code = this.promo?.code || "";
      this.dateStart = (this.promo?.dateStart as string) || "";
      this.dateEnd = (this.promo?.dateEnd as string) || "";
      this.max = this.promo?.max || undefined;
      this.discount = this.promo?.discount || undefined;
    },
    upload(image: File) {
      this.image = image;
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.image = file;
    },
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
    parseDate(date: string) {
      if (!date) return null;
      let d: string | string[] = date.split("/");

      d = `${d[2]}-${d[1]}-${d[0]}`;

      return moment(d).tz(tz).format("YYYY-MM-DD");
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SHOP_VOUCHER")) {
      this.$store.registerModule("SHOP_VOUCHER", promoStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SHOP_VOUCHER");
  },
});
